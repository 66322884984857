import { Box, IconButton, Typography, TypographyProps } from '@mui/material'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { CloseIcon } from '../icons/CloseIcon'
import { PdfIcon } from '../icons/PdfIcon'
import { UploadIcon } from '../icons/UploadIcon'

export enum FileUploadFileType {
  CSV = '.csv',
  PNG = '.png',
  JPEG = '.jpeg',
  JPG = '.jpg',
  PDF = '.pdf',
  DOCX = '.docx',
  DOC = '.doc',
  XLS = '.xls',
  XLSX = '.xlsx',
  TXT = '.txt',
}

// Add as needed from here https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export enum FileUploadMimeType {
  CSV = 'text/csv',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = 'application/msword',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  TXT = 'text/plain',
}

export type FileUploadProps = {
  onChange: (files: Array<File>) => void
  onBlur: () => void
  label?: string | React.ReactNode
  helperText?: string
  accept?: { [key in FileUploadMimeType]?: FileUploadFileType[] }
  acceptDefaults?: boolean
  defaultFiles?: File[]
  error?: boolean
  'data-testid'?: string
  labelVariant?: TypographyProps['variant']
}

const FileUpload = (props: FileUploadProps) => {
  const {
    onChange,
    onBlur,
    label,
    labelVariant,
    helperText,
    accept,
    acceptDefaults,
    defaultFiles,
    error,
    'data-testid': dataTestId,
  } = props
  const [files, setFiles] = useState<File[]>(defaultFiles || [])
  const onDrop = (newFiles: File[]) => {
    let updatedFiles = files.slice()
    updatedFiles = updatedFiles.concat(newFiles)
    setFiles(updatedFiles)
    onChange(updatedFiles)
    onBlur()
  }

  const removeFile = (idx: number) => {
    const updatedFiles = files.slice()
    updatedFiles.splice(idx, 1)
    setFiles(updatedFiles)
    onChange(updatedFiles)
    onBlur()
  }

  let dropZoneProps: any = {
    onDrop,
    maxSize: 100000000,
  }
  if (accept) {
    dropZoneProps = { ...dropZoneProps, accept }
  }

  if (acceptDefaults) {
    console.log('accepting defaults!')
    dropZoneProps = {
      ...dropZoneProps,
      accept: {
        [FileUploadMimeType.CSV]: [FileUploadFileType.CSV],
        [FileUploadMimeType.PNG]: [FileUploadFileType.PNG],
        [FileUploadMimeType.JPEG]: [FileUploadFileType.JPEG],
        [FileUploadMimeType.PDF]: [FileUploadFileType.PDF],
        [FileUploadMimeType.DOCX]: [FileUploadFileType.DOCX],
        [FileUploadMimeType.DOC]: [FileUploadFileType.DOC],
        [FileUploadMimeType.XLS]: [FileUploadFileType.XLS],
        [FileUploadMimeType.XLSX]: [FileUploadFileType.XLSX],
        [FileUploadMimeType.TXT]: [FileUploadFileType.TXT],
      },
    }
    console.log('!!!!', dropZoneProps)
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone(dropZoneProps)

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Typography variant={labelVariant || 'p2'}>{label || ''}</Typography>
      <Box
        {...getRootProps({ refKey: 'ref' })}
        sx={{
          color: 'primary.main',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '2px dashed',
          borderRadius: '24px',
          cursor: 'pointer',
        }}
      >
        <input
          {...getInputProps()}
          data-cy="fileUploadInput"
          data-testid={dataTestId}
          data-type="file-upload"
        />
        <Box
          sx={{
            display: 'flex',
            padding: '24px',
            gap: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <UploadIcon width="35px" />
          </Box>
          <Box>
            {isDragActive ? (
              <Typography>Drop file to upload</Typography>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                }}
              >
                <Typography
                  sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '24px' }}
                >
                  Drag and drop file or
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'green4.main',
                  }}
                >
                  browse files for upload
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {files.length !== 0 && (
        <>
          {files.map((file: File, idx: number) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'left',
                justifyContent: 'space-between',
                width: '100%',
              }}
              key={file.name}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="p3"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <PdfIcon color="primary" width="28px" />
                  {file.name}
                </Typography>
                <IconButton onClick={() => removeFile(idx)} sx={{ padding: 0 }}>
                  <CloseIcon width="24px" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </>
      )}
      <Typography variant="p3" color={error ? 'error.main' : 'inherit'}>
        {helperText}
      </Typography>
      {fileRejections?.length > 0 && (
        <Typography variant="p3" color="error.main">
          {fileRejections[0].errors[0].message.includes('larger') &&
            'Uploaded files must be less than 100MB.'}
        </Typography>
      )}
    </Box>
  )
}

FileUpload.defaultProps = {
  helperText: '',
  acceptDefaults: false,
}

export default FileUpload
