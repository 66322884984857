import { UserEventType } from '@flock/utils'
import {
  Button,
  ButtonProps as MuiButtonProps,
  InputAdornment,
  TextField,
  TextFieldProps,
  CircularProgress,
} from '@mui/material'
import React from 'react'
import {
  PatternFormat,
  PatternFormatProps,
  InputAttributes,
  usePatternFormat,
} from 'react-number-format'
import slugify from 'slugify'
import { TrackingConfig, useTracking } from '../useTracking'

export type ActionTextFieldProps = TextFieldProps & {
  ButtonProps?: MuiButtonProps
  ctaContent?: React.ReactNode
  onSubmit: () => void
  trackingConfig?: TrackingConfig
}

type CustomMaskProps = {
  onChange: (event: { target: { value: string | number | undefined } }) => void
  onBlur: () => void
  value: string | number
}

const PhoneFormat = React.forwardRef<
  PatternFormatProps<InputAttributes>,
  CustomMaskProps
>((props, ref) => {
  const { onChange, value, ...other } = props
  const { removeFormatting } = usePatternFormat({ format: '(###) ###-####' })

  const cleanedValue = value || ''

  return (
    <PatternFormat
      {...other}
      value={removeFormatting(cleanedValue as string)}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.formattedValue as string } })
      }}
      format="(###) ###-####"
      valueIsNumericString
    />
  )
})

const ActionTextField = (props: ActionTextFieldProps) => {
  const {
    size,
    label,
    ctaContent,
    onSubmit,
    ButtonProps,
    trackingConfig,
    disabled,
    ...otherProps
  } = props

  const { track } = useTracking()

  const onClickTracked = () => {
    let trackingName = 'action-text-field-submit'
    if (trackingConfig?.name) {
      trackingName = trackingConfig?.name
    } else if (label && typeof label === 'string') {
      trackingName = slugify(label?.toLowerCase())
    }
    track(trackingName, {
      type: 'button',
      ...trackingConfig?.properties,
      actionType: UserEventType.TEXT_FIELD_CLICK,
    })
    onSubmit()
  }

  let inputComponent: any
  if (label === 'Phone number' || label === 'Phone') {
    inputComponent = PhoneFormat
  }

  return (
    // @ts-ignore
    <TextField
      size={size}
      label={label}
      {...otherProps}
      InputProps={{
        notched: false,
        inputComponent,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              {...ButtonProps}
              onClick={onClickTracked}
              variant="primary"
              size={`${size as 'large' | 'small'}Form`}
              disabled={disabled}
              aria-label="Submit"
            >
              {disabled ? (
                <CircularProgress size="30px" color="white" />
              ) : (
                <>{ctaContent}</>
              )}
            </Button>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default ActionTextField

ActionTextField.defaultProps = {
  ButtonProps: {},
  ctaContent: '',
  trackingConfig: null,
}
